import Base from 'ember-simple-auth/authenticators/base';
import config from 'admin-panel/config/environment';
import { SessionData } from 'ember-simple-auth/services/session';

const {
  sentinel: { host, namespace },
} = config;

export default class SentinelAuthenticator extends Base {
  tokenEndpoint = '/users/sign_in';

  restore(data: SessionData) {
    return data?.auth_token ? Promise.resolve(data) : Promise.reject();
  }

  authenticate({
    data,
    authParams,
  }: {
    data: { email: string; password: string } | null;
    authParams?: {
      auth_token: string | undefined;
      client_id: string | undefined;
      user_id: string | undefined;
    };
  }) {
    if (authParams) return Promise.resolve(authParams);

    return this.sendRequest({ user: data });
  }

  async sendRequest(data: Record<string, unknown>) {
    const request = new Request(`${host}/${namespace}${this.tokenEndpoint}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
      method: 'POST',
    });

    const response = await fetch(request);

    if (!response.ok) {
      let errorObject;

      try {
        errorObject = await response.json();
      } catch {
        // ignore error
      }

      throw errorObject || response.statusText;
    }

    return response.json();
  }
}
