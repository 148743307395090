import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';

import type HotelModel from 'admin-panel/models/hotel';
import type PageModel from 'admin-panel/models/page';
import type ItemModel from 'admin-panel/models/item';

export default class CategoryModel extends Model {
  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @hasMany('page')
  declare pages: AsyncHasMany<PageModel>;

  @hasMany('item')
  declare items: AsyncHasMany<ItemModel>;

  @attr
  declare title: string;

  @attr({ defaultValue: '' })
  declare cron: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    category: CategoryModel;
  }
}
