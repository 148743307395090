import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type HotelModel from 'admin-panel/models/hotel';
import type RoomModel from 'admin-panel/models/room';

export default class OrderModel extends Model {
  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @belongsTo('room')
  declare room: AsyncBelongsTo<RoomModel>;

  @attr
  declare createdAt: string;

  @attr
  declare orderType: string;

  @attr
  declare state: string;

  @attr
  declare testOrder: boolean;

  @attr
  declare totalQuantity: number;

  @attr('nested-object')
  declare data: {
    timesToSkip: number;
  };

  get skippedCleanings() {
    return this.data?.timesToSkip ?? this.totalQuantity;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    order: OrderModel;
  }
}
