import JSONAPISerializer from '@ember-data/serializer/json-api';
import { recursivelyTransformObjectKeys } from 'admin-panel/utils/object';
import { camelize } from '@ember/string';

import type ModelRegistry from 'ember-data/types/registries/model'; // eslint-disable-line ember/use-ember-data-rfc-395-imports

export default class CharlotteTranslationSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(key: string) {
    return super.modelNameFromPayloadKey(`charlotte/${key}`);
  }

  payloadKeyFromModelName(modelName: keyof ModelRegistry) {
    const payloadKey = (modelName as string).replace('charlotte/', '');
    return super.payloadKeyFromModelName(payloadKey);
  }

  normalize<K extends keyof ModelRegistry>(
    modelClass: ModelRegistry[K],
    resourceHash: {
      id: string;
      type: keyof ModelRegistry;
      attributes: Record<string, unknown>;
    }
  ) {
    const data = {
      id: resourceHash.id,
      type: modelClass.modelName,
      attributes: recursivelyTransformObjectKeys(
        resourceHash.attributes,
        (key: string) => (key === 'hotel-id' ? camelize(key) : key)
      ),
    };

    return { data: data };
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'charlotte/translation': CharlotteTranslationSerializer;
  }
}
