import { warn } from '@ember/debug';
import ApplicationSerializer from 'admin-panel/serializers/application';

import type DS from 'ember-data'; // eslint-disable-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model'; // eslint-disable-line ember/use-ember-data-rfc-395-imports

interface RelationshipType {
  key: string;
  meta: {
    kind: string;
  };
}

export default class ItemSerializer extends ApplicationSerializer {
  // See https://github.com/emberjs/data/blob/c76c9d0a660986c349827e0c820bad10a7cf7acf/packages/serializer/addon/json.js#L1288
  // @ts-expect-error: Some type collision
  serializeHasMany<K extends keyof ModelRegistry>(
    snapshot: DS.Snapshot<K>,
    json: { [x: string]: DS.Snapshot<keyof ModelRegistry>[] },
    relationship: RelationshipType
  ) {
    const key = relationship.key as string;

    if (this.shouldSerializeHasMany(snapshot, key, relationship)) {
      const hasMany = snapshot.hasMany(
        key as Exclude<keyof ModelRegistry[K], keyof DS.Model>,
        { ids: true }
      );
      if (hasMany !== undefined) {
        // if provided, use the mapping provided by `attrs` in
        // the serializer
        let payloadKey = this._getMappedKey(key, snapshot.type);
        if (payloadKey === key && this.keyForRelationship) {
          payloadKey = this.keyForRelationship(key, 'hasMany');
        }

        // @ts-expect-error: Some type collision
        json[payloadKey] = hasMany;
        // TODO support for polymorphic manyToNone and manyToMany relationships
      }
    }
  }

  _getMappedKey<K extends keyof ModelRegistry>(
    key: string,
    modelClass: ModelRegistry[K]
  ) {
    warn(
      'There is no attribute or relationship with the name `' +
        key +
        '` on `' +
        modelClass.modelName +
        '`. Check your serializers attrs hash.',
      modelClass.attributes.has(key) || modelClass.relationshipsByName.has(key),
      {
        id: 'ds.serializer.no-mapped-attrs-key',
      }
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const attrs = this.attrs as any;
    let mappedKey;
    if (attrs && attrs[key]) {
      mappedKey = attrs[key];
      //We need to account for both the { title: 'post_title' } and
      //{ title: { key: 'post_title' }} forms
      if (mappedKey.key) {
        mappedKey = mappedKey.key;
      }
      if (typeof mappedKey === 'string') {
        key = mappedKey;
      }
    }

    return key;
  }

  // See https://github.com/emberjs/data/blob/c76c9d0a660986c349827e0c820bad10a7cf7acf/packages/serializer/addon/json.js#L933
  // @ts-expect-error: Some type collision
  shouldSerializeHasMany<K extends keyof ModelRegistry>(
    snapshot: DS.Snapshot<K>,
    key: string,
    relationshipType: RelationshipType
  ): boolean {
    if (
      key === 'integrationsRemoteReferences' &&
      relationshipType.meta.kind === 'hasMany'
    ) {
      return true;
    }

    // @ts-expect-error: Some type collision
    return super.shouldSerializeHasMany(snapshot, key, relationshipType);
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    item: ItemSerializer;
  }
}
