import Model, { attr } from '@ember-data/model';

export default class DeepDiveGreenOptionOrderModel extends Model {
  @attr
  declare year: string;

  @attr
  declare month: string;

  @attr
  declare numOrders: string;

  @attr
  declare cleaningsSkipped: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'deep-dive/green-option/order': DeepDiveGreenOptionOrderModel;
  }
}
