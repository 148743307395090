import Model, { attr } from '@ember-data/model';
import { type Integration } from 'admin-panel/constants';

export default class IntegrationsConfigurationModel extends Model {
  @attr
  declare serviceName: Integration;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'integrations/configuration': IntegrationsConfigurationModel;
  }
}
