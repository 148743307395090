import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/ember';

import type HotelModel from 'admin-panel/models/hotel';
import type StoreService from '@ember-data/store';
import type SentinelUserModel from 'admin-panel/models/sentinel/user';
import type SessionService from 'ember-simple-auth/services/session';
import type LocaleModel from 'admin-panel/models/locale';
import type CurrencyModel from 'admin-panel/models/currency';
import type SentinelRoleModel from 'admin-panel/models/sentinel/role';

interface SessionInfoSetupDefaults {
  contentLocale?: string;
}

export default class SessionInfoService extends Service {
  @service
  declare store: StoreService;

  @service
  declare session: SessionService;

  @tracked
  _isInitialized = false;

  @tracked
  declare _hotel: HotelModel;

  @tracked
  declare _user: SentinelUserModel;

  @tracked
  declare _roles: SentinelRoleModel[];

  @tracked
  declare _contentLocale: LocaleModel;

  @tracked
  declare _defaultLocale: LocaleModel;

  @tracked
  declare _defaultCurrency: CurrencyModel;

  /**
   * Locales, sorted according to the hotelLocale.position field.
   */
  @tracked
  declare _locales: LocaleModel[];

  get isInitialized() {
    return this._isInitialized;
  }

  get hotel() {
    return this._hotel;
  }

  get user() {
    return this._user;
  }

  get roles() {
    return this._roles;
  }

  get contentLocale() {
    return this._contentLocale;
  }

  get locales() {
    return this._locales;
  }

  get defaultCurrency() {
    return this._defaultCurrency;
  }

  async setup(defaults: SessionInfoSetupDefaults = {}) {
    const [hotel, user, roles] = await Promise.all([
      this.getDefaultHotel(),
      this.getUser(),
      this.getRoles(),
    ]);

    const [{ contentLocale, defaultLocale, locales }, defaultCurrency] =
      await Promise.all([
        this.setupLocales(hotel, defaults.contentLocale),
        hotel.defaultCurrency,
      ]);

    this._hotel = hotel;
    this._user = user;
    this._roles = roles.toArray();
    this._contentLocale = contentLocale;
    this._defaultLocale = defaultLocale;
    this._locales = locales;
    this._defaultCurrency = defaultCurrency;

    this._isInitialized = true;

    return this;
  }

  async getDefaultHotel() {
    const [defaultHotel] = (
      await this.store.query('hotel', {
        offset: 0,
        limit: 1,
        filter: {
          s: 'id',
        },
      })
    ).toArray();

    if (!defaultHotel) {
      throw new Error('No hotels available for current user!');
    }

    return defaultHotel;
  }

  getUser() {
    if (!this.session.isAuthenticated || !this.session.data.authenticated) {
      throw new Error("Unauthenticated: can't fetch the user data");
    }

    const { user_id: userId } = this.session.data.authenticated;

    Sentry.setUser({
      id: userId,
      ip_address: '{{auto}}',
    });

    return this.store.findRecord('sentinel/user', userId);
  }

  getRoles() {
    if (!this.session.isAuthenticated || !this.session.data.authenticated) {
      throw new Error("Unauthenticated: can't fetch the user roles");
    }

    const { user_id: userId } = this.session.data.authenticated;

    return this.store.query('sentinel/role', { userId });
  }

  async setupLocales(hotel: HotelModel, contentLocale?: string) {
    const hotelLocales = (await hotel.hotelLocales).toArray();
    const sortedHotelLocales = hotelLocales.sort(
      (a, b) => a.position - b.position
    );

    const sortedLocales = await Promise.all(
      sortedHotelLocales.map((hotelLocale) => hotelLocale.locale)
    );

    if (!sortedLocales.length)
      throw new Error('Hotel must have at least one locale assigned');

    const defaultLocale = sortedLocales[0] as LocaleModel;
    const locales = sortedLocales;

    const contentLocaleIntent =
      contentLocale || localStorage.getItem('currentContentLocale');
    const locale =
      contentLocaleIntent &&
      sortedLocales.find(({ key }) => key === contentLocaleIntent);

    return {
      contentLocale: locale || defaultLocale,
      defaultLocale: defaultLocale,
      locales,
    };
  }

  setContentLocale(newKey: string) {
    const locale = this.locales.find(({ key }) => key === newKey);

    this._contentLocale = locale || this._defaultLocale;
  }

  async setHotel(hotel: HotelModel) {
    const [{ contentLocale, defaultLocale, locales }, defaultCurrency] =
      await Promise.all([this.setupLocales(hotel), hotel.defaultCurrency]);

    this._hotel = hotel;
    this._contentLocale = contentLocale;
    this._defaultLocale = defaultLocale;
    this._locales = locales;
    this._defaultCurrency = defaultCurrency;
  }
}

declare module '@ember/service' {
  interface Registry {
    'session-info': SessionInfoService;
  }
}
