import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';

import type HotelModel from 'admin-panel/models/hotel';
import type ElasticHomeScreenTileModel from 'admin-panel/models/elastic/home-screen-tile';

export default class ElasticHomeScreenModel extends Model {
  @attr
  declare name: string;

  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @hasMany('elastic/home-screen-tile')
  declare homeScreenTiles: AsyncHasMany<ElasticHomeScreenTileModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'elastic/home-screen': ElasticHomeScreenModel;
  }
}
