import Model, { attr } from '@ember-data/model';

export default class ContentGroupModel extends Model {
  @attr
  declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-group': ContentGroupModel;
  }
}
