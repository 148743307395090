import ApplicationSerializer from 'admin-panel/serializers/application';

export default class PushSerializer extends ApplicationSerializer {
  attrs = {
    titleTranslations: 'dialog_title_translations',
    contentTranslations: 'dialog_content_translations',
    page: 'dialog_page_id',
    item: 'dialog_item_id',
    category: 'dialog_category_id',
    image: 'dialog_image_id',
    pushPlans: 'plan_ids',
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    push: PushSerializer;
  }
}
