import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import {
  PushPlanType,
  PUSH_PLAN_SCHEDULE_TYPES_ORDER,
  PushPlanScheduleType,
  PushPlanConditionParameter,
  PushPlanConditionOperator,
} from 'admin-panel/constants';

import type PushModel from './push';

export interface Condition {
  parameter: PushPlanConditionParameter;
  op: PushPlanConditionOperator;
  value: string;
  custom_expression?: string;
}

export default class PushPlanModel extends Model {
  @belongsTo('push')
  declare push: AsyncBelongsTo<PushModel>;

  @attr
  declare state: string;

  @attr
  declare type: PushPlanType;

  @attr
  declare timeOffsetDays: number;

  @attr
  declare timeOffsetSeconds: number;

  @attr
  declare conditionMode: 'all' | 'any';

  @attr
  declare conditions: Condition[];

  scheduleTypesOrder = PUSH_PLAN_SCHEDULE_TYPES_ORDER;

  get scheduleType() {
    const { type, timeOffsetDays } = this;

    return this.getPushPlanScheduleType(type, timeOffsetDays);
  }

  get position() {
    if (this.scheduleType === 'unknown') return 100;

    return this.scheduleTypesOrder.indexOf(this.scheduleType) + 1;
  }

  getPushPlanScheduleType(type: PushPlanType, timeOffsetDays: number) {
    let result;

    switch (type) {
      case PushPlanType.CheckinPlan:
        if (!timeOffsetDays) result = PushPlanScheduleType.FirstDay;
        if (timeOffsetDays === 1) result = PushPlanScheduleType.SecondDay;
        break;
      case PushPlanType.DeparturePlan:
        if (timeOffsetDays === -1)
          result = PushPlanScheduleType.SecondToLastDay;
        if (timeOffsetDays === 0) result = PushPlanScheduleType.LastDay;
        break;
      case PushPlanType.BirthdayPlan:
        result = PushPlanScheduleType.Birthday;
        break;
      case PushPlanType.DailyPlan:
        result = PushPlanScheduleType.EveryDay;
        break;
    }

    return result || 'unknown';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'push-plan': PushPlanModel;
  }
}
