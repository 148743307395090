import JSONAPISerializer from '@ember-data/serializer/json-api';

import type ModelRegistry from 'ember-data/types/registries/model'; // eslint-disable-line ember/use-ember-data-rfc-395-imports

export default class SentinelSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(key: string) {
    if (key.startsWith('sentinel-')) {
      key = key.slice(9);
    }

    return super.modelNameFromPayloadKey(`sentinel/${key}`);
  }

  payloadKeyFromModelName(modelName: keyof ModelRegistry) {
    return super.payloadKeyFromModelName(modelName).slice(9);
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    sentinel: SentinelSerializer;
  }
}
