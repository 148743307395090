import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { TranslatedField } from 'admin-panel/constants/types';
import { translated } from 'admin-panel/utils/model';
import type ItemModel from './item';
import type PageItemModel from './page-item';
import type CategoryModel from './category';

export default class PageModel extends Model {
  @hasMany('page-item')
  declare pageItems: AsyncHasMany<PageItemModel>;

  @hasMany('item')
  declare items: AsyncHasMany<ItemModel>;

  @belongsTo('category')
  declare category: AsyncBelongsTo<CategoryModel>;

  @attr
  declare cleanType: string;

  @attr
  declare titleTranslations: TranslatedField;

  @translated
  declare title: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    page: PageModel;
  }
}
