import SentinelAdapter from 'admin-panel/adapters/sentinel';

import type ModelRegistry from 'ember-data/types/registries/model'; // eslint-disable-line ember/use-ember-data-rfc-395-imports

export default class SentinelRoleAdapter extends SentinelAdapter {
  urlForQuery<K extends keyof ModelRegistry>(
    { userId }: Record<string, unknown>,
    modelName: K
  ) {
    if (!userId || typeof userId !== 'string')
      throw new Error(
        'You must pass a userId in order to query sentinel/roles'
      );

    return `${this.host}/${this.namespace}/users/${encodeURIComponent(
      userId
    )}/${this.pathForType(modelName)}`;
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'sentinel/role': SentinelRoleAdapter;
  }
}
