export const recursivelyTransformObjectKeys = (
  object: object,
  transformer: (key: string) => string
): object =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      transformer(key),
      typeof value === 'object' && value !== null
        ? Array.isArray(value)
          ? value.map((el) =>
              typeof el === 'object' && el !== null
                ? recursivelyTransformObjectKeys(el, transformer)
                : el
            )
          : recursivelyTransformObjectKeys(value, transformer)
        : value,
    ])
  );
