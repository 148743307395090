import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { translated } from 'admin-panel/utils/model';

import type PriceModel from 'admin-panel/models/price';
import type IntegrationsRemoteReferenceModel from 'admin-panel/models/integrations/remote-reference';
import type ImageModel from 'admin-panel/models/image';
import type PageModel from 'admin-panel/models/page';
import type CategoryModel from 'admin-panel/models/category';
import type HotelModel from 'admin-panel/models/hotel';
import { type TranslatedField } from 'admin-panel/constants/types';
import type PageItemModel from 'admin-panel/models/page-item';
import type ContentGroupModel from 'admin-panel/models/content-group';

export interface WorkflowOptions {
  cron?: string;
  maxQuantity?: string;
  maxPeopleCount?: string;
  minPeopleCount?: string;
}

export default class ItemModel extends Model {
  @hasMany('price')
  declare prices: AsyncHasMany<PriceModel>;

  @hasMany('integrations/remote-reference')
  declare integrationsRemoteReferences: AsyncHasMany<IntegrationsRemoteReferenceModel>;

  @hasMany('page')
  declare pages: AsyncHasMany<PageModel>;

  @hasMany('page-item')
  declare pageItems: AsyncHasMany<PageItemModel>;

  @belongsTo('image')
  declare image: AsyncBelongsTo<ImageModel>;

  @belongsTo('category')
  declare category: AsyncBelongsTo<CategoryModel>;

  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @belongsTo('content-group')
  declare contentGroup: AsyncBelongsTo<ContentGroupModel>;

  @attr
  declare workflow: string;

  @attr
  declare active: boolean;

  @attr
  declare visible: boolean;

  @attr
  declare titleTranslations: TranslatedField;

  @attr
  declare teaserTranslations: TranslatedField;

  @attr
  declare contentTranslations: TranslatedField;

  @attr
  declare linkUrlTranslations: TranslatedField;

  @attr
  declare linkTextTranslations: TranslatedField;

  @translated
  declare title: string;

  @translated
  declare teaser: string;

  @translated
  declare content: string;

  @translated
  declare linkUrl: string;

  @translated
  declare linkText: string;

  @attr('nested-object')
  declare workflowOptions: WorkflowOptions;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    item: ItemModel;
  }
}
