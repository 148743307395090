import { Resource } from 'ember-resources';
import { tracked } from '@glimmer/tracking';
import { action, get, set, setProperties } from '@ember/object';
import { task } from 'ember-concurrency';
import { assert, warn } from '@ember/debug';
import { setNestedObjectValues, setIn } from './utils';
import hash from 'object-hash';

type Prev = [never, 0, 1, 2, ...0[]];

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

type Paths<T, D extends number = 3> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never;
    }[keyof T]
  : '';

type Leaves<T, D extends number = 3> = [D] extends [never]
  ? never
  : T extends object
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : '';

export interface FormStateValues {
  [field: string]: unknown;
}

export type FormStateErrors<Values> = {
  [K in keyof Values]?: Values[K] extends unknown[]
    ? Values[K][number] extends object // [number] is the special sauce to get the type of array's element. More here https://github.com/Microsoft/TypeScript/pull/21316
      ? FormStateErrors<Values[K][number]>[]
      : string[] | string[][]
    : Values[K] extends object
    ? FormStateErrors<Values[K]>
    : string[];
};

export type FormStateTouched<Values> = {
  [K in keyof Values]?: Values[K] extends unknown[]
    ? Values[K][number] extends object // [number] is the special sauce to get the type of array's element. More here https://github.com/Microsoft/TypeScript/pull/21316
      ? (FormStateTouched<Values[K][number]> | undefined)[]
      : boolean | (boolean | undefined)[]
    : Values[K] extends object
    ? FormStateTouched<Values[K]>
    : boolean;
};

export type FormStateArgs<Values> = {
  initialValues: Values;
  onSubmit?: (values: Values) => void | Promise<unknown>;
  enableReinitialize?: boolean;
  initialErrors?: FormStateErrors<Values>;
  initialStatus?: unknown;
  initialTouched?: FormStateTouched<Values>;
  validateOnBlur?: boolean;
  validateOnInput?: boolean;
  validateOnMount?: boolean;
  onReset?: (values: Values) => void;
  validate?: (
    values: Values
  ) => FormStateErrors<Values> | Promise<FormStateErrors<Values> | unknown>;
};

interface FormStateCurrent<Values> {
  /** Form values */
  values: Values;
  /** map of field names to specific error for that field */
  errors: FormStateErrors<Values>;
  /** map of field names to whether the field has been touched */
  touched: FormStateTouched<Values>;
  /** Number of times user tried to submit the form */
  submitCount: number;
}

export default class FormState<
  Values extends FormStateValues = FormStateValues
> extends Resource<{
  Named: FormStateArgs<Values>;
}> {
  @tracked
  declare initialValues: Values;

  @tracked
  declare initialTouched: FormStateTouched<Values>;

  @tracked
  declare initialErrors: FormStateErrors<Values>;

  @tracked
  declare values: Values;

  @tracked
  declare touched: FormStateTouched<Values>;

  @tracked
  declare errors: FormStateErrors<Values>;

  declare onSubmit: FormStateArgs<Values>['onSubmit'];

  onReset?: FormStateArgs<Values>['onReset'];

  declare validate: FormStateArgs<Values>['validate'];

  private _previousRef: object | undefined;

  private _initialValuesHash = '';

  declare validateOnMount: boolean;

  declare validateOnInput: boolean;

  declare validateOnBlur: boolean;

  @tracked
  isValidating = false;

  @tracked
  isSubmitting = false;

  @tracked
  submitCount = 0;

  get isValid() {
    return !Object.keys(this.errors).length;
  }

  get dirty() {
    return this._initialValuesHash !== hash(this.values);
  }

  @action
  handleInput(event: Event) {
    if (!event) return;

    const { name, value } = this._parseEventTarget(event);

    if (get(this.values, name) === undefined) return;

    this.setFieldValue(
      name as Leaves<Values>,
      value as Values[Leaves<Values>],
      this.validateOnInput
    );
  }

  @action
  handleBlur(event: Event) {
    const { name } = this._parseEventTarget(event);

    if (get(this.values, name) === undefined) return;

    this.setFieldTouched(name, true, this.validateOnBlur);
  }

  @action
  async handleSubmit(event?: Event) {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    try {
      await this.submitForm();
    } catch (error) {
      console.warn(
        `Warning: An unhandled error was caught from submitForm()`,
        error
      );
    }
  }

  @action
  handleReset(event?: Event) {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    return this.resetForm();
  }

  @action
  submitForm() {
    return this._submitFormTask.perform();
  }

  @action
  validateForm(values: Values) {
    return this._validateFormTask.perform(values);
  }

  @action
  setErrors(errors: FormStateErrors<Values>) {
    this.errors = errors;
  }

  @action
  setFieldError(field: string, errors: string[]) {
    // @ts-expect-error: Some type mismatch
    set(this.errors, field, errors);

    // eslint-disable-next-line
    this.errors = this.errors;
  }

  @action
  setValues(
    values: Values | ((values: Values) => Values),
    shouldValidate = this.validateOnInput
  ) {
    if (typeof values === 'function') {
      this.values = values(this.values);
    } else {
      this.values = values;
    }

    if (shouldValidate) {
      this.validateForm(this.values);
    }
  }

  @action
  setFieldValue<K extends Leaves<Values> | Paths<Values>>(
    field: K,
    value: Values[K],
    shouldValidate = this.validateOnInput
  ) {
    set(this.values, field, value);

    // eslint-disable-next-line
    this.values = this.values;

    if (shouldValidate) {
      this.validateForm(this.values);
    }
  }

  @action
  setTouched(fields: FormStateTouched<Values>, shouldValidate = true) {
    this.touched = fields;

    if (shouldValidate) {
      this.validateForm(this.values);
    }
  }

  @action
  setFieldTouched(
    field: keyof Values,
    value: boolean,
    shouldValidate = this.validateOnBlur
  ) {
    /**
     * Using `set` instead of `setProperties` we're getting the following error in tests in Chrome only.
     *
     * Integration | Resource | form-state > it should be possible to change values of supported fields: array of string fields
     *
     * You attempted to update `touched` on `TestFormState`, but it had already been used previously in the same computation.
     * Attempting to update a value after using it in a computation can cause logical errors, infinite revalidation bugs,
     * and performance issues, and is not supported.
     */
    // @ts-expect-error: Some type mismatch
    setProperties(this.touched, setIn(this.touched, field, value));

    if (shouldValidate) {
      this.validateForm(this.values);
    }
  }

  @action
  async validateField(field: keyof Values) {
    if (!this.validate) return;

    try {
      this.isValidating = true;

      const errors = await this.validate({
        [field]: get(this.values, field),
      } as Values);

      if (!errors || !(field in errors)) return;

      const fieldErrors = (errors as FormStateErrors<Values>)[field];

      this.setFieldError(field as string, fieldErrors as string[]);
    } catch (error) {
      const message =
        error instanceof Error ? error.message : JSON.stringify(error);

      warn(`An error occurred during field validation! Error: ${message}`, {
        id: 'form-state.validation.field-error',
      });
    } finally {
      this.isValidating = true;
    }
  }

  @action
  setSubmitting(value: boolean) {
    this.isSubmitting = value;
  }

  @action
  async resetForm(nextState?: Partial<FormStateCurrent<Values>>) {
    const values = nextState?.values || this.initialValues;
    const errors = nextState?.errors || this.initialErrors;
    const touched = nextState?.touched || this.initialTouched;
    const submitCount = nextState?.submitCount ?? 0;

    await this.onReset?.(this.values);

    this._initialValuesHash = hash(values);
    this.values = values;
    this.errors = errors;
    this.touched = touched;
    this.submitCount = submitCount;
  }

  modify(_positional: [], options: FormStateArgs<Values>) {
    if (this._isInitialSetup(options)) {
      this._setup(options);
    }

    if (this.validateOnInput && this.validateOnBlur && this.validateOnMount) {
      this.validateForm(this.values);
    }
  }

  private _isInitialSetup(objectRef: object) {
    const isInitialSetup = !this._previousRef;
    this._previousRef = objectRef;

    return isInitialSetup;
  }

  private _setup(options: FormStateArgs<Values>) {
    const {
      initialValues,
      onSubmit,
      onReset,
      validate,
      initialTouched = {},
      initialErrors = {},
      validateOnMount = true,
      validateOnInput = true,
      validateOnBlur = true,
    } = options;

    this.initialValues = structuredClone(initialValues);
    this.values = structuredClone(initialValues);
    this._initialValuesHash = hash(initialValues);

    this.initialTouched = structuredClone(initialTouched);
    this.touched = structuredClone(initialTouched);

    this.initialErrors = structuredClone(initialErrors);
    this.errors = structuredClone(initialErrors);

    this.onSubmit = onSubmit;
    this.onReset = onReset;

    this.validate = validate;

    this.validateOnMount = validateOnMount;

    this.validateOnInput = validateOnInput;

    this.validateOnBlur = validateOnBlur;
  }

  private _submitFormTask = task(this, { drop: true }, async () => {
    try {
      // 1. Pre-submit
      this.setSubmitting(true);
      this.submitCount += 1;
      this._setAllFieldsTouched();

      // 2. Validation
      await this.validateForm(this.values);

      // 3. Submission
      if (this.isValid) {
        await this.onSubmit?.(this.values);
      }
    } catch (error) {
      const message =
        error instanceof Error ? error.message : JSON.stringify(error);

      warn(`An error occurred during submission! Error: ${message}`, {
        id: 'form-state.submit.error',
      });

      throw error;
    } finally {
      this.setSubmitting(false);
    }
  });

  private _validateFormTask = task(this, async (values: Values) => {
    if (!this.validate) return;

    try {
      this.isValidating = true;

      const errors = await Promise.resolve().then(() =>
        this.validate?.(values)
      );

      this.errors = errors as FormStateErrors<Values>;
    } catch (error) {
      const message =
        error instanceof Error ? error.message : JSON.stringify(error);

      warn(`An error occurred during validation! Error: ${message}`, {
        id: 'form-state.validation.error',
      });

      throw error;
    } finally {
      this.isValidating = false;
    }
  });

  private _parseEventTarget(event: Event) {
    assert(
      `Expected input event handler to be used on an element`,
      event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLSelectElement ||
        event.target instanceof HTMLTextAreaElement
    );

    const { name, type } = event.target;

    const value =
      type === 'checkbox'
        ? Boolean(
            (event.target as HTMLInputElement & { checked: boolean }).checked
          )
        : event.target.value;

    return {
      name,
      value,
    };
  }

  private _setAllFieldsTouched() {
    this.touched = setNestedObjectValues(this.values, true);
  }
}
