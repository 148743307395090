import Model, { attr } from '@ember-data/model';

export default class DeepDiveGreenOptionRoomModel extends Model {
  @attr
  declare roomCode: string;

  @attr
  declare createdAt: string;

  @attr
  declare skipFirstDatetime: string;

  @attr
  declare timesToSkip: string;

  @attr
  declare skipLastDatetime: string;

  @attr
  declare state: string;

  @attr
  declare todaysCutoffTime: string;

  @attr
  declare cutoffTimeHour: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'deep-dive/green-option/room': DeepDiveGreenOptionRoomModel;
  }
}
