import ActiveModelAdapter from 'active-model-adapter';
import config from 'admin-panel/config/environment';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object'; // eslint-disable-line ember/no-computed-properties-in-native-classes

import type SessionService from 'ember-simple-auth/services/session';

const {
  api: { host, namespace },
} = config;

export default class ApplicationAdapter extends ActiveModelAdapter {
  host = host;
  namespace = namespace;
  coalesceFindRequests = true;

  @service
  declare session: SessionService;

  @computed('session.{data.authenticated.access_token,isAuthenticated}')
  get headers() {
    const headers: Record<string, string> = {};

    if (this.session.isAuthenticated && this.session.data.authenticated) {
      const { auth_token: authToken, client_id: clientId } =
        this.session.data.authenticated;

      headers['Authorization'] = `Token ${authToken};client_id=${clientId}`;
    }

    return headers;
  }

  handleResponse(...args: Parameters<ActiveModelAdapter['handleResponse']>) {
    this.ensureResponseAuthorized(args[0]);

    return super.handleResponse(...args);
  }

  ensureResponseAuthorized(status: number) {
    // Normally only 401 responses are considered as unauthorized responses,
    // but Kraken returns 403 for when we try to access a resource with an
    // invalid token; to get the ember-simple-auth flow of re-authentication
    // via login route, we invalidate on 401s and 403s
    if ([401, 403].includes(status) && this.session.isAuthenticated) {
      this.session.invalidate();
    }
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    application: ApplicationAdapter;
  }
}
