import EmberRouter from '@embroider/router';
import config from 'admin-panel/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('authenticated', { path: '' }, function () {
    this.route('hotel', { path: '/:hotel_id' }, function () {
      this.route('dashboard', { path: '/' });
      this.route('hotel-list', { path: '/hotels' }, function () {
        this.route('hotel-edit', { path: '/:hotel_edit_id' });
        this.route('hotel-create', { path: '/new' });
      });
      this.route('currency-list', { path: '/currencies' }, function () {
        this.route('currency-edit', { path: '/:currency_edit_id' });
        this.route('currency-create', { path: '/new' });
      });
      this.route('locale-list', { path: '/locales' }, function () {
        this.route('locale-edit', { path: '/:locale_edit_id' });
        this.route('locale-create', { path: '/new' });
      });
      this.route('item-list', { path: '/items' }, function () {
        this.route('item-edit', { path: '/:item_edit_id' });
        this.route('item-create', { path: '/new' });
      });
      this.route('home-screen-list', { path: '/home-screens' }, function () {
        this.route(
          'byod-home-screen-tile-list',
          { path: '/byod' },
          function () {
            this.route('tile-edit', { path: '/:tile_edit_id' });
            this.route('tile-create', { path: '/new' });
          }
        );
      });
      this.route('room-list', { path: '/rooms' }, function () {
        this.route('room-edit', { path: '/:room_edit_id' });
        this.route('room-create', { path: '/new' });
      });
      this.route('order-list', { path: '/orders' }, function () {
        this.route('green-option', function () {
          this.route('dashboard', { path: '/' });
          this.route('order-list', { path: '/:year/:month' });
        });
      });
      this.route(
        'guest-journey-push-list',
        { path: '/guest-journey-pushes' },
        function () {
          this.route('push-edit', { path: '/:push_edit_id' });
          this.route('push-create', { path: '/new' });
        }
      );
      this.route(
        'stay-manager-step-list',
        { path: '/stay-manager/steps' },
        function () {
          this.route('index', { path: '/' });
          this.route('step-edit', { path: '/:step_edit_id' });
          this.route('step-create', { path: '/:type/new' });
        }
      );
    });
  });
});
