export const STAY_MANAGER_STEP_TRANSLATIONS = {
  'components.stay-manager.steps.green-option.entire-stay.title': {
    ar: 'ساعدنا على حماية البيئة',
    cs: 'Pomozte nám chránit životní prostředí',
    da: 'Hjælp os med at beskytte miljøet',
    de: 'Helfen Sie uns, die Umwelt zu schützen',
    el: 'Βοηθήστε μας να προστατέψουμε το περιβάλλον',
    en: 'Help Us Protect the Environment',
    es: 'Ayúdanos a proteger el medio ambiente',
    fi: 'Auta meitä suojelemaa',
    fr: "Aidez-nous à protéger l'environnement",
    he: 'עזרו לנו להגן על הסביבה',
    hr: 'Pomozite nam zaštititi okoliš',
    hu: 'Segítsen nekünk a környezet védelmében',
    id: 'Bantu Kami Melindungi Lingkungan',
    is: 'Hjálpaðu okkur að vernda umhverfið',
    it: "Aiutaci a salvaguardare l'ambiente",
    ja: '環境保護にご協力ください',
    ko: '환경 보호에 동참해 주세요',
    lv: 'Palīdziet mums aizsargāt vidi',
    ms: 'Bantu Kami Melindungi Alam Sekitar',
    nl: 'Help ons om het milieu te beschermen',
    pt: 'Ajude-nos a proteger o ambiente',
    ro: 'Ajutați-ne să protejăm mediul înconjurător',
    ru: 'Помогите нам защитить окружающую среду',
    sk: 'Pomôžte nám chrániť životné prostredie',
    sl: 'Pomagajte nam zaščititi okolje',
    sv: 'Hjälp oss att skydda miljön',
    th: 'ช่วยเราปกป้องสิ่งแวดล้อม',
    tr: 'Çevreyi Korumamıza Yardımcı Olun',
    zh: '帮助我们保护环境',
  },
  'components.stay-manager.steps.green-option.entire-stay.description': {
    ar: 'بما أنك ستقيم أكثر من ليلة, لديك الفرصة لاختيار إلغاء خدمة تنظيف الغرفة طوال مدة إقامتك. نُقدر قرارك!',
    cs: 'Vzhledem k tomu, že se ubytujete na více než jednu noc, máte možnost odmítnout úklid pokoje po dobu vašeho pobytu. Vážíme si vašeho rozhodnutí!',
    da: 'Eftersom dit ophold er længere end én nat, vil du have mulighed for at fravælge rengøring af værelset under hele dit ophold. Vi forstår din beslutning!',
    de: 'Da Sie länger als eine Nacht bleiben, haben Sie die Möglichkeit, für die Dauer Ihres Aufenthalts auf die Zimmerreinigung zu verzichten. Wir wissen Ihre Entscheidung sehr zu schätzen!',
    el: 'Εφόσον πραγματοποιείτε περισσότερες από μία διανυκτερεύσεις, έχετε τη δυνατότητα να επιλέξετε να παραληφθεί ο καθαρισμός δωματίου για όλη τη διάρκεια της διαμονής σας. Εκτιμούμε την απόφασή σας!',
    en: 'Since you are staying more than one night, you have the opportunity to opt out of room cleaning for the duration of your stay. We appreciate your decision!',
    es: 'Como vas a quedarte más de una noche, tienes la oportunidad de renunciar a la limpieza de tu habitación durante tu estancia. ¡Agradecemos tu decisión!',
    fi: 'Koska viivyt kauemmin kuin yhden yön, sinulla on mahdollisuus kieltää huoneen siivoaminen vierailusi ajan. Arvostamme päätöstäsi!',
    fr: "Étant donné que vous restez plus d'une nuit, vous avez la possibilité de ne pas faire nettoyer votre chambre pendant toute la durée de votre séjour. Nous vous remercions de votre décision !",
    he: 'מכיוון שאתם שוהים יותר מלילה אחד, יש לכם האפשרות לוותר על ניקיון החדר למשך שהותכם. אנו מעריכים את ההחלטה שלכם!',
    hr: 'S obzirom da ostajete više od jedne noći, imate mogućnost odustati od čišćenja sobe tijekom cijelog boravka. Cijenimo vašu odluku!',
    hu: 'Mivel egy éjszakánál hosszabb ideig marad, lehetősége van arra, hogy lemondjon a szoba takarításáról a tartózkodás idejére. Nagyra értékeljük a döntését!',
    id: 'Karena Anda menginap lebih dari semalam, Anda berkesempatan menolak kamar Anda dibersihkan selama durasi kunjungan Anda. Kami menghargai keputusan Anda!',
    is: 'Þar sem þú dvelur lengur en eina nótt hefurðu tækifæri til að afþakka herbergisþrif á meðan á dvöl þinni stendur. Við þökkum ákvörðun þína!',
    it: "Poiché soggiornerai più di una notte, hai l'opportunità di rinunciare alla pulizia della camera per tutta la durata del soggiorno. Gradiremmo che condividessi con noi la tua decisione!",
    ja: '複数泊の滞在の場合,滞在期間中の部屋の清掃をスキップすることができます。お客様のご判断に感謝します！',
    ko: '1박 이상 숙박할 경우, 숙박 기간 중 객실 청소를 생략할 수 있습니다. 고객님의 결정에 감사드립니다!',
    lv: 'Tā kā jūs uzturaties ilgāk par vienu nakti, jums ir iespēja atteikties no telpu uzkopšanas uz visu uzturēšanās laiku. Mēs novērtējam jūsu lēmumu!',
    ms: 'Memandangkan anda menginap lebih daripada satu malam, anda mempunyai peluang untuk tidak memilih pembersihan bilik sepanjang tempoh penginapan anda. Kami menghargai keputusan anda!',
    nl: 'Omdat u langer dan één nacht bij ons verblijft, kunt u tijdens uw verblijf afzien van het schoonmaken van de kamer. We horen graag van u!',
    pt: 'Considerando que vai ficar no nosso estabelecimento durante mais do que uma noite, tem a oportunidade de optar ativamente por não querer que o seu quarto seja limpo durante a sua estadia. Agradecemos que nos informe sobre a sua decisão!',
    ro: 'Întrucât stați mai mult de o noapte, aveți posibilitatea de a renunța la curățarea camerei pe durata șederii dumneavoastră. Apreciem decizia dumneavoastră!',
    ru: 'Поскольку вы останавливаетесь более чем на одну ночь, у вас есть возможность отказаться от уборки номера на время вашего пребывания. Мы ценим ваше решение!',
    sk: 'Keďže zostávate viac ako jednu noc, máte možnosť odhlásiť sa z upratovania izieb počas trvania vášho pobytu. Vážime si vaše rozhodnutie!',
    sl: 'Ker boste ostali več kot eno noč, imate možnost, da odjavite čiščenje sobe v času bivanja. Cenimo vašo odločitev.',
    sv: 'Eftersom du stannar mer än en natt har du möjlighet att välja bort rumsstädning under vistelsen. Vi uppskattar ditt beslut!',
    th: 'เนื่องจากคุณเข้าพักนานกว่าหนึ่งคืน คุณมีโอกาสที่จะยกเลิกการทำความสะอาดห้องตลอดระยะเวลาเข้าพักของคุณได้ เราเคารพการตัดสินใจของคุณ!',
    tr: 'Bir geceden fazla kalacağınız için konaklamanız süresince oda temizliği hizmetinden muaf olma imkanına sahipsiniz. Kararınıza değer veriyoruz!',
    zh: '由于您入住超过一晚,您有机会选择在住宿期间无需客房打扫服务。我们感谢您的决定！',
  },
  'components.stay-manager.steps.breakfast-upsell.booking.title.tomorrow': {
    ar: 'هل ترغب في تناول الإفطار غدًا؟',
    cs: 'Chtěli byste zítra snídat?',
    da: 'Vil du have morgenmad i morgen?',
    de: 'Möchten Sie morgen frühstücken?',
    el: 'Θέλετε Να Πάρετε Πρωινό Αύριο;',
    en: 'Would You Like to Have Breakfast Tomorrow?',
    es: '¿Te gustaría desayunar mañana?',
    fi: 'Haluatko aamiaisen huomenna?',
    fr: 'Souhaitez-vous prendre un petit-déjeuner demain ?',
    he: 'רוצה לאכול ארוחת בוקר מחר?',
    hr: 'Želite li sutra doručkovati?',
    hu: 'Szeretne holnap reggelit kapni?',
    id: 'Apakah Anda Ingin Sarapan Besok?',
    is: 'Viltu borđa morgunmat á morgun?',
    it: 'Vorresti fare colazione domani?',
    ja: '明日の朝食をご希望ですか？',
    ko: '내일 조식을 드실 예정인가요?',
    lv: 'Vai vēlaties rīt ieturēt brokastis?',
    ms: 'Adakah Anda Mahu Makan Sarapan Pagi Esok?',
    nl: 'Wil je morgen ontbijt?',
    pt: 'Gostaria de tomar o pequeno-almoço amanhã?',
    ro: 'Doriți să luați micul dejun mâine?',
    ru: 'Хотели бы вы позавтракать завтра?',
    sk: 'Chceli by ste zajtra raňajky?',
    sl: 'Bi radi jutri zajtrkovali?',
    sv: 'Vill du ha frukost imorgon?',
    th: 'อยากรับอาหารเช้าพรุ่งนี้ไหม',
    tr: 'Yarın Kahvaltı Yapmak İster misin?',
    zh: '明天需要早餐吗？',
  },
  'components.stay-manager.steps.breakfast-upsell.booking.title.today': {
    ar: 'هل ترغب في تناول الإفطار اليوم؟',
    cs: 'Chtěli byste dnes snídat?',
    da: 'Vil du have morgenmad i dag?',
    de: 'Möchten Sie heute frühstücken?',
    el: 'Θέλετε Να Πάρετε Πρωινό Σήμερα;',
    en: 'Would You Like to Have Breakfast Today?',
    es: '¿Te gustaría desayunar hoy?',
    fi: 'Haluatko aamiaisen tänään?',
    fr: "Souhaitez-vous prendre un petit-déjeuner aujourd'hui ?",
    he: 'רוצה לאכול ארוחת בוקר היום?',
    hr: 'Želite li danas doručkovati?',
    hu: 'Szeretne ma reggelit kapni?',
    id: 'Apakah Anda Ingin Sarapan Hari Ini?',
    is: 'Viltu borđa morgunmat í dag?',
    it: 'Vorresti fare colazione oggi?',
    ja: '本日の朝食をご希望ですか？',
    ko: '오늘 조식을 드실 예정인가요?',
    lv: 'Vai vēlaties šodien brokastot?',
    ms: 'Adakah Anda Mahu Makan Sarapan Pagi Hari Ini?',
    nl: 'Wil je vandaag ontbijt?',
    pt: 'Gostaria de tomar o pequeno-almoço hoje?',
    ro: 'Ați dori să luați micul dejun astăzi?',
    ru: 'Хотели бы вы позавтракать сегодня?',
    sk: 'Chceli by ste dnes raňajky?',
    sl: 'Bi radi danes zajtrkovali?',
    sv: 'Vill du ha frukost idag?',
    th: 'อยากรับอาหารเช้าวันนี้ไหม',
    tr: 'Bugün Kahvaltı Yapmak İster misin?',
    zh: '您今天需要早餐吗？',
  },
  'components.stay-manager.steps.breakfast-upsell.more-options.info': {
    ar: 'مجانًا للأطفال أقل من 6 سنوات',
    cs: 'Děti do 6 let mají nic neplatí',
    da: 'Børn under 6 år er gratis',
    de: 'Kinder unter 6 Jahren sind kostenfrei',
    el: 'Για παιδιά κάτω των 6 ετών είναι δωρεάν',
    en: 'Children under 6 years are free of charge',
    es: 'Los menores de 6 años no pagan.',
    fi: 'Alle 6-vuotiaat lapset pääsevät ilmaiseksi',
    fr: 'Gratuit pour les enfants de moins de six ans',
    he: 'לא צריך לשלם עבור ילדים מתחת לגיל 6',
    hr: 'Djeca mlađa od 6 godina ne plaćaju',
    hu: '6 év alatti gyermekek számára ingyenes',
    id: 'Anak-anak di bawah 6 tahun tidak dikenai biaya',
    is: 'Börn yngri en 6 ára fá frítt',
    it: 'I bambini sotto i 6 anni non pagano',
    ja: '6歳未満のお子様は無料',
    ko: '6세 미만 어린이는 무료입니다',
    lv: 'Bērniem līdz 6 gadu vecumam ir bez maksas',
    ms: 'Kanak-kanak bawah 6 tahun adalah percuma',
    nl: 'Voor kinderen onder de 6 is het gratis',
    pt: 'As crianças com menos de 6 anos não pagam',
    ro: 'Copiii sub 6 ani beneficiază de gratuitate',
    ru: 'Детям до 6 лет бесплатно',
    sk: 'Bezplatne pre deti do 6 rokov',
    sl: 'Otroci do 6 let so brezplačni',
    sv: 'Barn under 6 år äter gratis',
    th: 'เด็กอายุต่ำกว่า 6 ปีไม่เสียเงิน',
    tr: '6 yaşından küçük çocuklara ücretsizdir',
    zh: '6 岁以下儿童免费',
  },
  'components.stay-manager.thanks.title.standard': {
    ar: 'شكرًا لك على تصميم إقامتك!',
    cs: 'Děkujeme za potvrzení pobytu!',
    da: 'Tak for at du tilpasser dit ophold!',
    de: 'Danke, dass Sie Ihren Aufenthalt personalisiert haben!',
    el: 'Ευχαριστούμε που διαμορφώσατε τη διαμονή σας!',
    en: 'Thank You for Configuring Your Stay!',
    es: '¡Gracias por configurar tu estancia!',
    fi: 'Kiitos, että määritit vierailusi.',
    fr: "Merci d'avoir configuré votre séjour !",
    he: 'תודה שהגדרתם את השהות שלכם!',
    hr: 'Hvala vam što ste konfigurirali svoj boravak!',
    hu: 'Köszönjük, hogy konfigurálta tartózkodását!',
    id: 'Terima Kasih Telah Mengonfigurasi Kunjungan Anda!',
    is: 'Þakka þér fyrir að stilla dvöl þína!',
    it: 'Grazie per aver configurato il tuo soggiorno!',
    ja: 'ご滞在を設定していただきありがとうございます！',
    ko: '숙박을 맞춤 설정해 주셔서 감사합니다!',
    lv: 'Paldies, ka konfigurējāt savu uzturēšanos!',
    ms: 'Terima kasih kerana Mengkonfigurasi Penginapan Anda!',
    nl: 'Dank u voor het configureren van uw verblijf!',
    pt: 'Obrigado por configurar a sua estadia!',
    ro: 'Vă mulțumim că v-ați configurat șederea!',
    ru: 'Спасибо за то, что сообщили предпочтения относительно вашего пребывания!',
    sk: 'Ďakujeme, že ste si nastavili svoj pobyt!',
    sl: 'Hvala za prilagoditev vašega bivanja.',
    sv: 'Tack för att du konfigurerar din vistelse!',
    th: 'ขอบคุณสำหรับการปรับแต่งการเข้าพักของคุณ!',
    tr: 'Konaklamanızı Yapılandırdığınız için Teşekkür Ederiz!',
    zh: '感谢您为自己的住宿进行了设置！',
  },
  'components.stay-manager.thanks.description.standard': {
    ar: 'SuitePad خاصتك جاهز للاستخدام الآن. لا تتردد في استكشاف ما يثير اهتمامك. إذا كانت لديك أي أسئلة, لا تتردد في التواصل مع الاستقبال. استمتع بإقامتك!',
    cs: 'Váš SuitePad je nyní připraven k použití. Neváhejte a prozkoumejte, co vás zajímá. V případě jakýchkoliv dotazů se neváhejte obrátit na recepci. Užijte si pobyt!',
    da: 'Din SuitePad er nu klar til brug. Udforsk gerne det, der interesserer dig. Hvis du har nogen spørgsmål, så tøv ikke med at kontakte receptionen. Nyd dit ophold!',
    de: 'Ihr SuitePad ist jetzt einsatzbereit, schauen Sie sich gerne um. Wenn Sie Fragen haben, zögern Sie nicht, die Rezeption zu kontaktieren. Genießen Sie Ihren Aufenthalt!',
    el: 'Το SuitePad σας είναι πλέον έτοιμο για χρήση. Εξερευνήστε ελεύθερα ό,τι σας ενδιαφέρει. Εάν έχετε ερωτήσεις, μη διστάσετε να επικοινωνήσετε με τη ρεσεψιόν. Καλή διαμονή!',
    en: "Your SuitePad is now ready to be used. Feel free to explore what interests you. If you have any questions, don't hesitate to contact the reception. Enjoy your stay!",
    es: 'Tu SuitePad está ya listo para usarse. Siéntete libre de explorar lo que te interese. Si tienes alguna pregunta, no dudes en ponerte en contacto con recepción. ¡Disfruta de tu estancia!',
    fi: 'SuitePadisi on nyt valmis käytettäväksi. Tutustu vapaasti kaikkeen sinua kiinnostavaan. Mikäli sinulla on kysyttävää, ota epäröimättä yhteys vastaanottoon. Nauti vierailustasi!',
    fr: "Votre SuitePad est maintenant prêt à être utilisé. N'hésitez pas à explorer ce qui vous intéresse. Si vous avez des questions, n'hésitez pas à contacter la réception. Bon séjour !",
    he: 'ה-SuitePad שלכם מוכן כעת לשימוש. אתם מוזמנים לגלות מה מעניין אתכם. אם יש לכם שאלות כלשהן, אל תהססו לפנות לדלפק הקבלה. תיהנו מהשהות שלכם!',
    hr: 'Vaš SuitePad sada je spreman za korištenje. Slobodno istražujte ono što vas zanima. Ako imate bilo kakvih pitanja, nemojte se ustručavati kontaktirati recepciju. Uživajte u svom boravku!',
    hu: 'A SuitePad már készen áll a használatra. Nyugodtan fedezze fel, ami érdekli. Ha bármilyen kérdése van, ne habozzon kapcsolatba lépni a recepcióval. Kellemes időtöltést!',
    id: 'SuitePad Anda kini siap digunakan. Silakan menelusuri hal yang menarik bagi Anda. Jika Anda ingin bertanya, jangan ragu menghubungi resepsi. Silakan menikmati kunjungan Anda!',
    is: 'SuitePad er nú tilbúið til notkunar. Ekki hika við að kanna hvað vekur áhuga þinn. Ef þú hefur einhverjar spurningar skaltu ekki hika við að hafa samband við móttökuna. Njóttu dvalarinnar!',
    it: 'Adesso il tuo SuitePad è pronto per essere utilizzato. Puoi esplorare liberamente ciò che ti interessa. Se hai domande, non esitare a contattare la reception. Buon soggiorno!',
    ja: 'SuitePadを使用する準備が整いました。興味のあることを自由に調べてみてください。ご不明な点などがありましたら,お気軽に受付までお問い合わせください。それでは滞在をお楽しみください！',
    ko: '이제 SuitePad를 사용할 준비가 되었습니다. 관심 있는 항목을 자유롭게 둘러보세요. 질문이 있으시면 언제든지 리셉션으로 문의하세요. 그럼 즐거운 시간 보내시길 바랍니다!',
    lv: 'Tagad jūsu SuitePad ir gatavs lietošanai. Varat brīvi izpētīt, kas jūs interesē. Ja jums ir kādi jautājumi, sazinieties ar reģistratūru. Izbaudiet savu uzturēšanos!',
    ms: 'SuitePad anda kini sedia untuk digunakan. Jangan ragu-ragu untuk meneroka perkara yang menarik minat anda. Jika anda mempunyai apa-apa pertanyaan, jangan teragak-agak untuk menghubungi kaunter menyambut tetamu. Nikmati penginapan anda!',
    nl: 'Uw SuitePad is nu klaar voor gebruik. Voel u vrij om te verkennen wat u interesseert. Mocht u vragen hebben, aarzel dan niet om contact op te nemen met de receptie. Prettig verblijf!',
    pt: 'Agora, o seu SuitePad já está pronto para ser utilizado. Fique à vontade para explorar aquilo que lhe interessa. Se tiver alguma dúvida, não hesite em contactar a receção. Desfrute da sua estadia!',
    ro: 'SuitePad-ul dumneavoastră este acum gata să fie utilizat. Puteți explora în deplină libertate ceea ce vă interesează. Dacă aveți întrebări, nu ezitați să contactați recepția. Bucurați-vă de șederea dumneavoastră!',
    ru: 'Ваш SuitePad готов к использованию. Не стесняйтесь исследовать то, что вас интересует. Если у вас есть какие-либо вопросы, не стесняйтесь обращаться на стойку регистрации. Приятного пребывания!',
    sk: 'Váš SuitePad je teraz pripravený na použitie. Neváhajte a preskúmajte, čo vás zaujíma. V prípade akýchkoľvek otázok neváhajte kontaktovať recepciu. Užite si pobyt!',
    sl: 'Vaš SuitePad je zdaj pripravljen za uporabo. Raziščite, kar vas zanima. Če imate kakršna koli vprašanja, se obrnite na recepcijo. Prijetno bivanje!',
    sv: 'Din SuitePad är nu redo att användas. Utforska gärna det som intresserar dig. Tveka inte att kontakta receptionen om du har några frågor. Trevlig vistelse!',
    th: 'ขณะนี้ SuitePad ของคุณพร้อมให้ใช้งานแล้ว เชิญสำรวจสิ่งที่คุณสนใจได้ตามสบาย หากคุณมีคำถามใดๆ อย่าลังเลที่จะติดต่อแผนกต้อนรับ พักผ่อนให้สบาย!',
    tr: "SuitePad'iniz artık kullanıma hazır. İlginizi çeken şeyleri keşfetmekten çekinmeyin. Herhangi bir sorunuz varsa resepsiyonla iletişime geçmekten çekinmeyin. Konaklamanızın tadını çıkarın!",
    zh: '您的 SuitePad 现在可以使用了。随意探索您感兴趣的内容。如果您有任何疑问,请随时联系前台。祝您入住愉快！',
  },
};
