import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';

import type HotelModel from 'admin-panel/models/hotel';
import type RoomModel from 'admin-panel/models/room';

export default class RoomControlConfigurationModel extends Model {
  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @hasMany('room')
  declare rooms: AsyncHasMany<RoomModel>;

  @attr
  declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'room-control/configuration': RoomControlConfigurationModel;
  }
}
