import Service from '@ember/service';
import { allSettled, didCancel, Task, task } from 'ember-concurrency';
import { service } from '@ember/service';
import Evented from '@ember/object/evented';
import RSVP from 'rsvp';

import type RouterService from '@ember/routing/router-service';
import { type Transition } from 'admin-panel/utils/type-utils';

export interface ModalContext {
  exitAnimationTask: Task<unknown, never[]>;
}

export default class ModalManagerService extends Service.extend(Evented) {
  @service
  declare router: RouterService;

  private _abortInProgress = false;

  private _boundTransitionHandler:
    | ((transition: Transition) => Promise<void>)
    | null = null;

  private _modals: Set<ModalContext> = new Set();

  get hasOpenModals() {
    return !!this._modals.size;
  }

  register(context: ModalContext) {
    this._modals.add(context);
  }

  unregister(context: ModalContext) {
    this._modals.delete(context);
  }

  async closeOpenModals() {
    try {
      await this.allExitAnimationsTask.perform();
    } catch (error) {
      if (!didCancel(error)) throw error;
    }
  }

  setupTransitionHandler() {
    this._boundTransitionHandler = this.handleTransition.bind(this);
    this.router.on('routeWillChange', this._boundTransitionHandler);
  }

  removeTransitionHandler() {
    if (this._boundTransitionHandler) {
      this.router.off('routeWillChange', this._boundTransitionHandler);
      this._boundTransitionHandler = null;
    }
  }

  async handleTransition(transition: Transition) {
    if (!transition.from) return;

    switch (transition.from.name) {
      case 'authenticated.hotel.stay-manager-step-list.step-edit':
        if (
          transition.from.name === transition.to.name &&
          transition.from.params['step_edit_id'] ===
            transition.to.params['step_edit_id']
        ) {
          return;
        }
        break;

      case 'authenticated.hotel.stay-manager-step-list.step-create':
        if (
          transition.from.name === transition.to.name &&
          transition.from.params['type'] === transition.to.params['type']
        ) {
          return;
        }
        break;

      default:
        if (transition.from.name === transition.to.name) {
          return;
        }
    }

    if (!this.hasOpenModals || this._abortInProgress) return;

    try {
      this._abortInProgress = true;

      transition.abort();

      if (this.has('transitionAborted')) {
        const deferred = RSVP.defer();

        deferred.reject = deferred.reject.bind(this, 'DEFER REJECTED');

        this.trigger('transitionAborted', deferred);

        await deferred.promise;
      }

      await this.closeOpenModals();

      transition.retry();
    } catch (error) {
      // ignore defer reject
      if (error !== 'DEFER REJECTED') throw error;
    } finally {
      this._abortInProgress = false;
    }
  }

  allExitAnimationsTask = task({ drop: true }, async () => {
    await allSettled(
      [...this._modals].map(async (context) => {
        await context.exitAnimationTask.perform();

        this.unregister(context);
      })
    );
  });
}

declare module '@ember/service' {
  interface Registry {
    'modal-manager': ModalManagerService;
  }
}
