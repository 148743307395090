import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { translated } from 'admin-panel/utils/model';
import { TranslatedField } from 'admin-panel/constants/types';

import type ElasticHomeScreenTileModel from 'admin-panel/models/elastic/home-screen-tile';
import type CategoryModel from 'admin-panel/models/category';
import type ImageModel from 'admin-panel/models/image';
import type HotelModel from 'admin-panel/models/hotel';

export default class ElasticTileModel extends Model {
  @hasMany('elastic/home-screen-tile')
  declare homeScreenTiles: AsyncHasMany<ElasticHomeScreenTileModel>;

  @belongsTo('category')
  declare category: AsyncBelongsTo<CategoryModel>;

  @belongsTo('image')
  declare image: AsyncBelongsTo<ImageModel>;

  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @attr
  declare imageUrl: string;

  @attr
  declare titleTranslations: TranslatedField;

  @attr
  declare teaserTranslations: TranslatedField;

  @translated
  declare title: string;

  @translated
  declare teaser: string;

  @attr
  declare tileType: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'elastic/tile': ElasticTileModel;
  }
}
