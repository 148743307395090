import ActiveModelAdapter from 'active-model-adapter';
import config from 'admin-panel/config/environment';
import { computed } from '@ember/object'; // eslint-disable-line ember/no-computed-properties-in-native-classes
import { service } from '@ember/service';

import type SessionService from 'ember-simple-auth/services/session';

const {
  deepDive: { host, namespace },
} = config;

export default class DeepDiveAdapter extends ActiveModelAdapter {
  host = host;
  namespace = namespace;

  @service
  declare session: SessionService;

  @computed('session.{data.authenticated.access_token,isAuthenticated}')
  get headers() {
    const headers: Record<string, string> = {};

    if (this.session.isAuthenticated && this.session.data.authenticated) {
      const { auth_token: authToken, client_id: clientId } =
        this.session.data.authenticated;

      headers['Authorization'] = `Token ${authToken};client_id=${clientId}`;
    }

    return headers;
  }

  handleResponse(...args: Parameters<ActiveModelAdapter['handleResponse']>) {
    this.ensureResponseAuthorized(args[0]);

    return super.handleResponse(...args);
  }

  ensureResponseAuthorized(status: number) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'deep-dive': DeepDiveAdapter;
  }
}
