import Model, { attr } from '@ember-data/model';

export default class ElasticFilterDefinitionModel extends Model {
  @attr('nested-object', {
    defaultValue() {
      return { meta: { name: '' } };
    },
  })
  declare definition: {
    meta: {
      name: string;
    };
  };

  get name() {
    return this.definition.meta.name;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'elastic/filter-definition': ElasticFilterDefinitionModel;
  }
}
