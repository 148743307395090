import Transform from '@ember-data/serializer/transform';
import { camelize, underscore } from '@ember/string';
import { recursivelyTransformObjectKeys } from 'admin-panel/utils/object';

export default class NestedObjectTransform extends Transform {
  deserialize(serialized: object) {
    if (typeof serialized !== 'object') return serialized;

    return recursivelyTransformObjectKeys(serialized, camelize);
  }

  serialize(deserialized: object) {
    if (typeof deserialized !== 'object') return deserialized;

    return recursivelyTransformObjectKeys(deserialized, underscore);
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'nested-object': NestedObjectTransform;
  }
}
