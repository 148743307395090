import { assert } from '@ember/debug';
import { ParentIframeMessage } from 'admin-panel/constants';

export function sendMessageToParent(message: string) {
  assert(
    `You tried to pass an unsupported message. "${message}" cannot be sent to the parentIframe.`,
    Object.values(ParentIframeMessage).includes(message as ParentIframeMessage)
  );
  window.parentIFrame?.sendMessage(message);
}
