import './tailwind/main.css';
import 'iframe-resizer';

import { initSentry } from 'admin-panel/utils/sentry';
import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'admin-panel/config/environment';

initSentry();

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
