export { default as AVAILABLE_TIMEZONES } from './timezones';

export enum LanguageDirection {
  Ltr = 'ltr',
  Rtl = 'rtl',
}

export enum UiLanguage {
  EnUs = 'en-us',
  FrFr = 'fr-fr',
  DeDe = 'de-de',
  EsEs = 'es-es',
}

export const DEFAULT_UI_LANGUAGE = UiLanguage.EnUs;

export enum WorkflowCode {
  ExpressCheckout = 'expressCheckout',
  Email = 'email',
  ByodSplashScreen = 'byodSplashScreen',
  SpFeedback = 'spFeedback',
  RoomBill = 'roomBill',
  ByodShopStandard = 'byodShopStandard',
  AdminNotWorkingOnSgt47 = 'admin_not_working_on_sgt47',
  ExternalURL = 'externalURL',
  QuickFeedback = 'quickFeedback',
  InstantCall = 'instantCall',
  CustomCall = 'customCall',
  ReservationItem = 'reservationItem',
  RequestItem = 'requestItem',
  GreenOption = 'greenOption',
  ReservationItemNoConfirmation = 'reservation-item-no-confirmation',
  InformationItem = 'informationItem',
  GreenOptionV2 = 'green-option-v2',
  BreakfastUpsell = 'breakfast-upsell',
}

export enum ParentIframeMessage {
  BackToCategoryList = 'backToCategoryList',
  BackToPageEdit = 'backToPageEdit',
}

export const DEFAULT_CATEGORY_CRON = '*/10 * * * *';

// There are several content groups listed in the drop down menu that we do not use since they are group leaders for several sub categories.
// See https://jaws.suitepad.systems/youtrack/issue/apan-293 for reference.
// This can be removed once the ContentGroup model is updated with the proper flags, indicating wether CG has a sub-category.
export const CONTENT_GROUPS_LEADER_IDS = [3, 4, 6, 8];

export enum OrderState {
  Requested = 'requested',
  Confirmed = 'confirmed',
  Declined = 'declined',
}

export enum DeepDiveGreenOptionModal {
  SettingsModal = 'settings-modal',
  SkippedCleaningsModal = 'skipped-cleanings-modal',
  SavedCostsModal = 'saved-costs-modal',
}

export const DEFAULT_GREEN_OPTION_CUTOFF_TIME = 13;

export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export enum GreenOptionSkipCleaningRoomsOrderState {
  ConfirmedOnly = 'confirmed-only',
  AllNotDeclined = 'all-not-declined',
}

export enum PushDialogType {
  ItemDialog = 'ItemDialog',
  MessageDialog = 'MessageDialog',
  PageDialog = 'PageDialog',
}

export enum PushPlanType {
  CheckinPlan = 'PushPlans::CheckinPlan',
  DailyPlan = 'PushPlans::DailyPlan',
  DeparturePlan = 'PushPlans::DeparturePlan',
  BirthdayPlan = 'PushPlans::BirthdayPlan',
}

export enum PushPlanScheduleType {
  FirstDay = 'first-day',
  SecondDay = 'second-day',
  SecondToLastDay = 'second-to-last-day',
  LastDay = 'last-day',
  Birthday = 'birthday',
  EveryDay = 'every-day',
}

export const PUSH_PLAN_SCHEDULE_TYPES_ORDER = [
  PushPlanScheduleType.FirstDay,
  PushPlanScheduleType.SecondDay,
  PushPlanScheduleType.SecondToLastDay,
  PushPlanScheduleType.LastDay,
  PushPlanScheduleType.Birthday,
  PushPlanScheduleType.EveryDay,
];

export enum ScheduleManagerPushPlanType {
  CheckIn = 'check-in',
  FirstDay = 'first-day',
  SecondDay = 'second-day',
  SecondToLastDay = 'second-to-last-day',
  LastDay = 'last-day',
  Birthday = 'birthday',
  EveryDay = 'every-day',
}

export const PUSH_PLAN_SCHEDULE_TYPES = [
  {
    id: ScheduleManagerPushPlanType.CheckIn,
    type: PushPlanType.CheckinPlan,
    timeOffsetDays: null,
  },
  {
    id: ScheduleManagerPushPlanType.FirstDay,
    type: PushPlanType.CheckinPlan,
    timeOffsetDays: 0,
  },
  {
    id: ScheduleManagerPushPlanType.SecondDay,
    type: PushPlanType.CheckinPlan,
    timeOffsetDays: 1,
  },
  {
    id: ScheduleManagerPushPlanType.SecondToLastDay,
    type: PushPlanType.DeparturePlan,
    timeOffsetDays: -1,
  },
  {
    id: ScheduleManagerPushPlanType.LastDay,
    type: PushPlanType.DeparturePlan,
    timeOffsetDays: 0,
  },
  {
    id: ScheduleManagerPushPlanType.Birthday,
    type: PushPlanType.BirthdayPlan,
    timeOffsetDays: null,
  },
  {
    id: ScheduleManagerPushPlanType.EveryDay,
    type: PushPlanType.DailyPlan,
    timeOffsetDays: null,
  },
];

export const PUSH_DIALOG_TYPES = [
  { id: 'message', key: PushDialogType.MessageDialog },
  { id: 'item', key: PushDialogType.ItemDialog },
  { id: 'page', key: PushDialogType.PageDialog },
];

export enum PushPlanConditionParameter {
  LengthOfStay = 'length_of_stay',
  RemainingNights = 'remaining_nights',
  RateCode = 'rate_code',
  BookingChannel = 'booking_channel',
  NumAdults = 'num_adults',
  ExpertFilter = 'custom_expression',
}

export enum PushPlanConditionOperator {
  Equal = '==',
  NotEqual = '!=',
  LessThan = '<',
  LessThanOrEqualTo = '<=',
  GreaterThanOrEqualTo = '>=',
  GreaterThan = '>',
  Match = 'in',
  NotMatch = 'not in',
}

export const PUSH_PLAN_CONDITION_PARAMETERS = [
  { id: 'length-of-stay', key: PushPlanConditionParameter.LengthOfStay },
  { id: 'remaining-nights', key: PushPlanConditionParameter.RemainingNights },
  { id: 'num-adults', key: PushPlanConditionParameter.NumAdults },
  { id: 'rate-code', key: PushPlanConditionParameter.RateCode },
  { id: 'booking-channel', key: PushPlanConditionParameter.BookingChannel },
  { id: 'expert-filter', key: PushPlanConditionParameter.ExpertFilter },
];

export const PUSH_PLAN_CONDITION_OPERATORS = [
  { id: 'equal', key: PushPlanConditionOperator.Equal },
  { id: 'not-equal', key: PushPlanConditionOperator.NotEqual },
  { id: 'less-than', key: PushPlanConditionOperator.LessThan },
  {
    id: 'less-than-or-equal-to',
    key: PushPlanConditionOperator.LessThanOrEqualTo,
  },
  {
    id: 'greater-than-or-equal-to',
    key: PushPlanConditionOperator.GreaterThanOrEqualTo,
  },
  { id: 'greater-than', key: PushPlanConditionOperator.GreaterThan },
  { id: 'match', key: PushPlanConditionOperator.Match },
  { id: 'not-match', key: PushPlanConditionOperator.NotMatch },
];

export enum Integration {
  Apaleo = 'apaleo',
  Asa = 'asa',
  Fias = 'fias',
  Guestline = 'guestline',
  Hotelkit = 'hotelkit',
  Hotsoft = 'hotsoft',
  Infor = 'infor',
  Mews = 'mews',
  Protel = 'protel',
  Sihot = 'sihot',
  SimphonyGen1 = 'simphony_gen1',
  Stayntouch = 'stayntouch',
  Suite8 = 'suite8',
}

export enum IntegrationType {
  PMS = 'pms',
  POS = 'pos',
  SRS = 'srs',
}

export const AVAILABLE_INTEGRATIONS = [
  { id: Integration.Apaleo, type: IntegrationType.PMS },
  { id: Integration.Asa, type: IntegrationType.PMS },
  { id: Integration.Fias, type: IntegrationType.PMS },
  { id: Integration.Guestline, type: IntegrationType.PMS },
  { id: Integration.Hotelkit, type: IntegrationType.SRS },
  { id: Integration.Hotsoft, type: IntegrationType.PMS },
  { id: Integration.Infor, type: IntegrationType.PMS },
  { id: Integration.Mews, type: IntegrationType.PMS },
  { id: Integration.Protel, type: IntegrationType.PMS },
  { id: Integration.Sihot, type: IntegrationType.PMS },
  { id: Integration.SimphonyGen1, type: IntegrationType.POS },
  { id: Integration.Stayntouch, type: IntegrationType.PMS },
  { id: Integration.Suite8, type: IntegrationType.PMS },
];

export enum StayManagerStepType {
  DepartureStep = 'StayManager::DepartureStep',
  GreenOptionStep = 'StayManager::GreenOptionStep',
  CarbonOffsetStep = 'StayManager::CarbonOffsetStep',
  BreakfastUpsellStep = 'StayManager::BreakfastUpsellStep',
  ThanksStep = 'StayManager::ThanksStep',
}

export enum StayManagerStepCode {
  Departure = 'departure',
  GreenOption = 'green-option',
  CarbonOffset = 'carbon-offset',
  BreakfastUpsell = 'breakfast-upsell',
  Thanks = 'thanks',
}

export enum StayManagerTranslationKey {
  GreenOptionTitle = 'components.stay-manager.steps.green-option.entire-stay.title',
  GreenOptionDescription = 'components.stay-manager.steps.green-option.entire-stay.description',
  BreakfastUpsellTitleTomorrow = 'components.stay-manager.steps.breakfast-upsell.booking.title.tomorrow',
  BreakfastUpsellTitleToday = 'components.stay-manager.steps.breakfast-upsell.booking.title.today',
  BreakfastUpsellDescription = 'components.stay-manager.steps.breakfast-upsell.more-options.info',
  ThanksTitle = 'components.stay-manager.thanks.title.standard',
  ThanksDescription = 'components.stay-manager.thanks.description.standard',
}

export enum HotelStatus {
  Test = 'test',
  Onboarding = 'onboarding',
  Active = 'active',
  Inactive = 'inactive',
  Unknown = 'unknown',
}

export const AVAILABLE_HOTEL_STATUSES = Object.values(HotelStatus);
