export enum ItemType {
  Information = 'information',
  Shop = 'shop',
  Request = 'request',
  Reservation = 'reservation',
  Special = 'special',
}

export enum InputName {
  Title = 'title',
  Teaser = 'teaser',
  Content = 'content',
  Link = 'link',
  Price = 'price',
  Cron = 'cron',
  MaxQuantity = 'max-quantity',
  MaxPeopleCount = 'max-people-count',
  MinPeopleCount = 'min-people-count',
  RemoteReference = 'remote-reference',
  ContentGroup = 'content-group',
}

const itemTypeToInputMatrix = [
  [1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0],
  [1, 1, 0, 0, 1, 0, 1, 0, 0, 1, 1],
  [1, 1, 1, 0, 1, 1, 0, 0, 0, 1, 1],
  [1, 1, 0, 0, 1, 1, 0, 1, 1, 1, 1],
  [1, 1, 0, 1, 1, 1, 0, 0, 0, 1, 1],
];

const inputNameToIndexMap = {
  [InputName.Title]: 0,
  [InputName.Teaser]: 1,
  [InputName.Content]: 2,
  [InputName.Link]: 3,
  [InputName.Price]: 4,
  [InputName.Cron]: 5,
  [InputName.MaxQuantity]: 6,
  [InputName.MaxPeopleCount]: 7,
  [InputName.MinPeopleCount]: 8,
  [InputName.RemoteReference]: 9,
  [InputName.ContentGroup]: 10,
};

const itemTypeToIndexMap = {
  [ItemType.Information]: 0,
  [ItemType.Shop]: 1,
  [ItemType.Request]: 2,
  [ItemType.Reservation]: 3,
  [ItemType.Special]: 4,
};

export const shouldShowInput = (itemType: ItemType, inputName: InputName) => {
  const typeIndex = itemTypeToIndexMap[itemType];
  const inputIndex = inputNameToIndexMap[inputName];

  return !!itemTypeToInputMatrix[typeIndex]?.[inputIndex];
};
