import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type HotelModel from 'admin-panel/models/hotel';
import {
  StayManagerStepCode,
  StayManagerStepType,
} from 'admin-panel/constants';

const SUPPORTED_STAY_MANAGER_STEP_MAPPINGS = {
  [StayManagerStepType.DepartureStep]: StayManagerStepCode.Departure,
  [StayManagerStepType.GreenOptionStep]: StayManagerStepCode.GreenOption,
  [StayManagerStepType.CarbonOffsetStep]: StayManagerStepCode.CarbonOffset,
  [StayManagerStepType.BreakfastUpsellStep]:
    StayManagerStepCode.BreakfastUpsell,
  [StayManagerStepType.ThanksStep]: StayManagerStepCode.Thanks,
};

export default class StayManagerStepModel extends Model {
  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @attr
  declare active: boolean;

  @attr
  declare position: number;

  @attr
  declare type: StayManagerStepType;

  @attr('nested-object')
  declare config: {
    maxLengthOfStay: number;
    maxNumberOfSkips: number;
    includeSecondChanceScreen: boolean;
    footprintPerNight: number;
    offsetCostPerTon: number;
    askingFrequency: number;
    regularItemId: number;
    childrenItemId: number;
    cutoffTime: number;
    rateCodeFilter: {
      rateCodes: string[];
      mode: 'hide' | 'show';
    };
  };

  get code() {
    return SUPPORTED_STAY_MANAGER_STEP_MAPPINGS[this.type];
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'stay-manager/step': StayManagerStepModel;
  }
}
