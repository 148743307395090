import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type HotelModel from 'admin-panel/models/hotel';
import type RoomControlConfigurationModel from 'admin-panel/models/room-control/configuration';

export default class RoomModel extends Model {
  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @belongsTo('room-control/configuration')
  declare roomControlConfiguration: AsyncBelongsTo<RoomControlConfigurationModel>;

  @attr
  declare chatVisible: boolean;

  @attr
  declare code: string;

  @attr
  declare jabberId: string;

  @attr
  declare jabberPassword: string;

  @attr
  declare sipAuthId: string;

  @attr
  declare sipHost: string;

  @attr
  declare sipPassword: string;

  @attr
  declare sipPort: string;

  @attr
  declare sipProxy: string;

  @attr
  declare sipUser: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    room: RoomModel;
  }
}
