import StoreService from '@ember-data/store';
import fetch from 'fetch';
import { recursivelyTransformObjectKeys } from 'admin-panel/utils/object';
import { camelize } from '@ember/string';

// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type AdapterRegistry from 'ember-data/types/registries/adapter';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';
import type Model from '@ember-data/model';

interface DeepDiveResponse<T> {
  count: number;
  columns: string[];
  data: T[];
}

export default class ExtendedStoreService extends StoreService {
  async bulkUpdate(modelName: keyof ModelRegistry, records: Model[]) {
    const keyName = this.adapterFor(
      modelName as keyof AdapterRegistry
    ).pathForType(modelName);

    const serializedRecords = records.map(
      (record) => record.serialize({ includeId: true }) as Model
    );

    await this.sendBulkRequest(
      modelName,
      { [keyName]: serializedRecords },
      'PATCH'
    );

    this.pushPayload(modelName, { [keyName]: serializedRecords });

    return Promise.resolve();
  }

  async bulkDestroy(modelName: keyof ModelRegistry, records: Model[]) {
    const ids = records.map((record) => record.id);

    await this.sendBulkRequest(modelName, { ids }, 'DELETE');

    this._pushDeletion(modelName, records);

    return Promise.resolve();
  }

  async sendBulkRequest(
    modelName: keyof ModelRegistry,
    payload: { ids: string[] } | { [key: string]: Model[] },
    method: 'DELETE' | 'PATCH'
  ) {
    const adapter = this.adapterFor(modelName as keyof AdapterRegistry);
    const url = adapter.buildURL(modelName);

    const response = await fetch(`${url}/bulk`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        ...adapter.headers,
      },
      body: JSON.stringify(payload),
      method,
    });

    if (response.ok) {
      return response.json();
    }

    const errorObject = await response.json();

    throw errorObject;
  }

  async queryDeepDive<T>(
    modelName: keyof ModelRegistry,
    queryName: string,
    hotelUuid: string,
    queryParams: { [key: string]: string }
  ) {
    if (this.peekAll(modelName).length)
      return this.peekAll(modelName).toArray() as T[];

    const adapter = this.adapterFor('deep-dive');
    const url = adapter.buildURL();
    const searchParams = new URLSearchParams(queryParams);

    const response = await fetch(
      `${url}/${queryName}/${hotelUuid}?${searchParams}`,
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          ...adapter.headers,
        },
        method: 'GET',
      }
    );

    if (response.ok) {
      const json = await response.json();

      const camelizedResponse = recursivelyTransformObjectKeys(
        json,
        camelize
      ) as DeepDiveResponse<T>;

      camelizedResponse.data.forEach((record, i) => {
        const normalizedResponse = this.normalize(modelName, {
          ...record,
          id: i + 1,
        });

        this.push(normalizedResponse);
      });

      return this.peekAll(modelName).toArray() as T[];
    }

    const errorObject = await response.json();

    throw errorObject;
  }

  private _pushDeletion(type: keyof ModelRegistry, records: Model[]) {
    records.forEach((record) => {
      record.deleteRecord();

      if (record !== null) {
        const relationships: Record<string, { data: [] | null }> = {};
        let hasRelationships = false;

        record.eachRelationship((name: string, { kind }: { kind: string }) => {
          hasRelationships = true;
          relationships[name] = {
            data: kind === 'hasMany' ? [] : null,
          };
        });

        if (hasRelationships) {
          this.push({
            data: {
              type,
              id: record.id,
              relationships,
            },
          });
        }

        record.unloadRecord();
      }
    });
  }
}
