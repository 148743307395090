import Model, { attr } from '@ember-data/model';

export default class CharlotteTranslationModel extends Model {
  @attr
  declare hotelId: string;

  @attr
  declare locale: string;

  @attr
  declare definition: {
    [key: string]: string;
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'charlotte/translation': CharlotteTranslationModel;
  }
}
