import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { computed } from '@ember/object'; // eslint-disable-line ember/no-computed-properties-in-native-classes
import { service } from '@ember/service';
import config from 'admin-panel/config/environment';

import type ModelRegistry from 'ember-data/types/registries/model'; // eslint-disable-line ember/use-ember-data-rfc-395-imports
import type SessionService from 'ember-simple-auth/services/session';

const {
  sentinel: { host, namespace },
} = config;

export default class SentinelAdapter extends JSONAPIAdapter {
  host = host;
  namespace = namespace;

  @service
  declare session: SessionService;

  @computed('session.{data.authenticated.access_token,isAuthenticated}')
  get headers() {
    const headers: Record<string, string> = {};

    if (this.session.isAuthenticated && this.session.data.authenticated) {
      const { auth_token: authToken, client_id: clientId } =
        this.session.data.authenticated;

      headers['Authorization'] = `Token ${authToken};client_id=${clientId}`;
    }

    return headers;
  }

  pathForType(modelName: keyof ModelRegistry) {
    const sentinel = (modelName as string).match(/^sentinel\/(.+?)$/);

    return super.pathForType((sentinel && sentinel[1]) || modelName);
  }

  handleResponse(...args: Parameters<JSONAPIAdapter['handleResponse']>) {
    this.ensureResponseAuthorized(args[0]);

    return super.handleResponse(...args);
  }

  ensureResponseAuthorized(status: number) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    sentinel: SentinelAdapter;
  }
}
