import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import type ItemModel from './item';
import type PageModel from './page';

export default class PageItemModel extends Model {
  @belongsTo('page')
  declare page: AsyncBelongsTo<PageModel>;

  @belongsTo('item')
  declare item: AsyncBelongsTo<ItemModel>;

  @attr
  declare position: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'page-item': PageItemModel;
  }
}
