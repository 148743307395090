import { getOwner } from '@ember/application';
import { assert } from '@ember/debug';
import { dependentKeyCompat } from '@ember/object/compat';

import type SessionInfoService from 'admin-panel/services/session-info';

type DecoratorPropertyDescriptor =
  | (PropertyDescriptor & { initializer?: unknown })
  | undefined;

type ElementDescriptor = [
  target: object,
  propertyName: string,
  descriptor?: DecoratorPropertyDescriptor
];

function isElementDescriptor(args: unknown[]): args is ElementDescriptor {
  const [maybeTarget, maybeKey, maybeDesc] = args;

  return (
    // Ensure we have the right number of args
    args.length === 3 &&
    // Make sure the target is a class or object (prototype)
    (typeof maybeTarget === 'function' ||
      (typeof maybeTarget === 'object' && maybeTarget !== null)) &&
    // Make sure the key is a string
    typeof maybeKey === 'string' &&
    // Make sure the descriptor is the right shape
    ((typeof maybeDesc === 'object' && maybeDesc !== null) ||
      maybeDesc === undefined)
  );
}

const wrapTranslatedGetterAndSetter = function (...args: ElementDescriptor) {
  const [target, key, _desc] = args;

  return (
    dependentKeyCompat as (
      ...depArgs: [
        ...Parameters<PropertyDecorator>,
        DecoratorPropertyDescriptor
      ]
    ) => void
  )(target, key, {
    configurable: true,
    enumerable: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get: function (this: any) {
      const owner = getOwner(this);
      const translationsKey = `${key}Translations`;

      assert(
        `Attempting to lookup an injected property on an object without a container, ensure that the object was instantiated via a container.`,
        owner
      );
      assert(
        `${translationsKey} doesn't exist on target!`,
        translationsKey in this
      );

      const sessionInfo = owner.lookup(
        'service:session-info'
      ) as SessionInfoService;
      const { contentLocale, locales } = sessionInfo;

      let translation;
      for (const locale of [contentLocale, ...locales]) {
        if (translation) break;

        if (!this[translationsKey]) {
          this[translationsKey] = {};
        }

        translation = this[translationsKey][locale.key];
      }

      return translation || '';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set: function (this: any, value: any) {
      const owner = getOwner(this);
      const translationsKey = `${key}Translations`;

      assert(
        `Attempting to lookup an injected property on an object without a container, ensure that the object was instantiated via a container.`,
        owner
      );
      assert(
        `${translationsKey} doesn't exist on target!`,
        translationsKey in this
      );

      const sessionInfo = owner.lookup(
        'service:session-info'
      ) as SessionInfoService;

      this[translationsKey][sessionInfo.contentLocale.key] = value;
    },
  });
};

export function translated(
  ...args: [] | [translationKey: string] | ElementDescriptor
) {
  if (isElementDescriptor(args)) {
    const [target, key, desc] = args;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return wrapTranslatedGetterAndSetter(target, key, desc) as unknown as any;
  }

  assert(
    'Calling @translated decorator with named translations object is not implemented yet!',
    false
  );
}
