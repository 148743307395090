import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type HotelModel from './hotel';
import type LocaleModel from './locale';

export default class HotelLocaleModel extends Model {
  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @belongsTo('locale')
  declare locale: AsyncBelongsTo<LocaleModel>;

  @attr
  declare position: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'hotel-locale': HotelLocaleModel;
  }
}
