import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type IntegrationsConfigurationModel from 'admin-panel/models/integrations/configuration';

export default class IntegrationsRemoteReferenceModel extends Model {
  @belongsTo('integrations/configuration')
  declare configuration: AsyncBelongsTo<IntegrationsConfigurationModel>;

  @attr
  declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'integrations/remote-reference': IntegrationsRemoteReferenceModel;
  }
}
