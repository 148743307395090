// Ascending  => `fieldName`
// Descending => `fieldName desc`
// None       => ``
export const parseTableSorts = (
  sorts: { valuePath: string; isAscending: boolean }[] | undefined,
  existingSort: {
    valuePath: string | undefined;
    isAscending: boolean;
  }[]
) => {
  if (!existingSort[0]?.valuePath) return '';
  if (!sorts?.length || !sorts[0])
    return !existingSort[0].isAscending
      ? existingSort[0].valuePath
      : `${existingSort[0].valuePath} desc`;

  const { isAscending, valuePath } = sorts[0];

  return isAscending ? valuePath : `${valuePath} desc`;
};

// Ascending  => `fieldName`
// Descending => `fieldName desc`
// None       => ``
export const parseSortingQp = (input: string) => {
  const [valuePath = '', isDescending] = input.split(' ');

  return {
    valuePath,
    isAscending: !isDescending,
  };
};
