import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';

import type LocaleModel from 'admin-panel/models/locale';
import type HotelLocaleModel from 'admin-panel/models/hotel-locale';
import type CurrencyModel from './currency';
import { type HotelStatus } from 'admin-panel/constants';

export default class HotelModel extends Model {
  @belongsTo('locale')
  declare defaultLocale: AsyncBelongsTo<LocaleModel>;

  @hasMany('locale')
  declare locales: AsyncHasMany<LocaleModel>;

  @hasMany('hotel-locale')
  declare hotelLocales: AsyncHasMany<HotelLocaleModel>;

  @hasMany('hotel', { inverse: null })
  declare hotelMixin: AsyncHasMany<HotelModel>;

  @belongsTo('currency')
  declare defaultCurrency: AsyncBelongsTo<CurrencyModel>;

  @attr
  declare name: string;

  @attr
  declare status: HotelStatus;

  @attr
  declare code: string;

  @attr
  declare timezone: string;

  @attr
  declare tzInfoId: string;

  @attr
  declare uuid: string;

  @attr
  declare type: string;

  @attr
  declare theme: string;

  @attr
  declare pin: string;

  @attr('nested-object')
  declare options: {
    greenOptionCutoffTime: number;
    tedInitScript: string;
    stayManagerEnabled: boolean;
  };

  @attr
  declare sharedInstallationMetadata: { [key: string]: string }[];

  get isSharedInstallation() {
    return this.type === 'HotelMixin';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    hotel: HotelModel;
  }
}
