import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type CurrencyModel from './currency';
import type ItemModel from './item';

export default class PriceModel extends Model {
  @belongsTo('item')
  declare item: AsyncBelongsTo<ItemModel>;

  @belongsTo('currency')
  declare currency: AsyncBelongsTo<CurrencyModel>;

  @attr('string', { defaultValue: '0' })
  declare value: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    price: PriceModel;
  }
}
