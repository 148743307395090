import Model, { attr } from '@ember-data/model';

export default class CurrencyModel extends Model {
  @attr
  declare name: string;

  @attr
  declare code: string;

  @attr
  declare symbol: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    currency: CurrencyModel;
  }
}
