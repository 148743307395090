import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type ElasticTileModel from 'admin-panel/models/elastic/tile';
import type ElasticHomeScreenModel from 'admin-panel/models/elastic/home-screen';
import type ElasticFilterDefinitionModel from 'admin-panel/models/elastic/filter-definition';

export default class ElasticHomeScreenTileModel extends Model {
  @belongsTo('elastic/home-screen')
  declare homeScreen: AsyncBelongsTo<ElasticHomeScreenModel>;

  @belongsTo('elastic/tile')
  declare tile: AsyncBelongsTo<ElasticTileModel>;

  @belongsTo('elastic/filter-definition')
  declare filter: AsyncBelongsTo<ElasticFilterDefinitionModel>;

  @attr('number')
  declare priority: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'elastic/home-screen-tile': ElasticHomeScreenTileModel;
  }
}
