import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { translated } from 'admin-panel/utils/model';

import { type TranslatedField } from 'admin-panel/constants/types';
import { type PushDialogType } from 'admin-panel/constants';
import type HotelModel from './hotel';
import type RoomModel from './room';
import type ImageModel from './image';
import type CategoryModel from './category';
import type PageModel from './page';
import type ItemModel from './item';
import type PushPlanModel from './push-plan';

export default class PushModel extends Model {
  @belongsTo('category')
  declare category: AsyncBelongsTo<CategoryModel>;

  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @belongsTo('image')
  declare image: AsyncBelongsTo<ImageModel>;

  @belongsTo('page')
  declare page: AsyncBelongsTo<PageModel>;

  @belongsTo('item')
  declare item: AsyncBelongsTo<ItemModel>;

  @hasMany('room')
  declare rooms: AsyncHasMany<RoomModel>;

  @hasMany('push-plan')
  declare pushPlans: AsyncHasMany<PushPlanModel>;

  @attr
  declare contentTranslations: TranslatedField;

  @attr
  declare titleTranslations: TranslatedField;

  @attr
  declare active: boolean;

  @attr
  declare dialogType: PushDialogType;

  @translated
  declare title: string;

  @attr
  declare ttl: number;

  @attr
  declare type: 'one_off' | 'planned' | 'recurring';
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    push: PushModel;
  }
}
