import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import config from 'admin-panel/config/environment';

import type HotelModel from 'admin-panel/models/hotel';

export default class ImageModel extends Model {
  @belongsTo('hotel')
  declare hotel: AsyncBelongsTo<HotelModel>;

  @attr
  declare tags: string[];

  @attr
  declare versions: { preview?: string } | null;

  @dependentKeyCompat
  get previewUrl() {
    return this.versions?.preview
      ? `${config.api.host}${this.versions.preview}`
      : '';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    image: ImageModel;
  }
}
